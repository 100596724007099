import { Language, GroupFunction } from "./Enum";

/**
 * The accessobject as it is saved on the server.
 */
export interface AccessObject {
  id?: string;
  groupId: string;
  grantedBy: string;
  accessLevels: AccessObjectLevels;
}

export interface AccessObjectLevels {
  WERK: string | null;
  CENTER: string | null;
  ABTEILUNG: string | null;
  KOSTENSTELLE: string | null;
  LINIE: string | null;
  STATION: string | null;
}

/**
 * The user as it is on the backend database.
 */
export interface User {
  id: string | undefined;
  mail: string;
  name: string;
  language: Language | undefined;
  department: string;
  company: string;
  groupObject: Group;
  groupId: string;
  blocked: boolean;
  loginCounter: number;
  password?: string;
}

/**
 * Helper method to create an empty service user.
 */
export const createEmptyUser = (): User => ({
  id: undefined,
  mail: "",
  name: "",
  department: "",
  company: "",
  groupObject: createEmptyGroup(),
  groupId: "",
  blocked: false,
  loginCounter: 0,
  language: undefined,
});

export interface Group {
  id: string;
  name: string;
  description: string;
  role: "ADMIN" | "EDITOR" | "VIEWER" | "";
  groupFunctions?: GroupFunction[];
}

export interface GroupEntry {
  id: string;
  content: any[];
  group: Group;
}

/**
 * Helper method to create an empty service group.
 */
export const createEmptyGroup = (): Group => ({
  id: "",
  name: "",
  description: "",
  role: "",
});

/**
 * A user object containing accumulated keycloak fields.
 */
export interface KeycloakUser {
  username?: string;
  emailVerified?: boolean;
  isAdmin: boolean;
  isEditor: boolean;
  isViewer: boolean;
  name?: string;
}

/**
 * Helper method to create an empty keycloak user.
 */
export const createEmptyKeycloakUser = (): KeycloakUser => ({
  username: "",
  emailVerified: false,
  isAdmin: false,
  isEditor: false,
  isViewer: false,
  name: "",
});

/**
 * The Station object as it is on the backend
 */
export interface Station {
  id: string | undefined;
  createdBy: string | undefined;
  version: number;
  lastUpdated: Date;
  imageId: string;
  annotation: string;
  description: string;
  name: string;
  linieId: string;
  linieName: string;
  kostenstelleName: string;
  werkstuckTyp: string;
  sollTakt: number;
  oemSupplier: string;
  hmi: string;
  op: string;
  vk?: string[];
  templateVersion: string;

  egStationData?: number;
  egZsStationData?: number;
  egMaterialFluss?: number;
  egProzessabgrenzung?: number;
  egBetriebszustaende?: number;
  egZsProzesseAndSockets?: number;
  egZsDatenfluss?: number;

  freezed?: boolean;
}

/**
 * This is the Wrapper object for the Powerwall Station EG chart.
 * REMEMBER TO CHANGE THE NAMES IN THE COMPONENT AS WELL IF ANYTHING HAS TO BE CHANGED!
 */
export interface StationBarData {
  "Station Name": string;
  name: "PRISMA EG" | "ZS EG";
  value: number;
}

/**
 * This is the Wrapper object for the Powerwall Linie Reifegrad chart.
 * REMEMBER TO CHANGE THE NAMES IN THE COMPONENT AS WELL IF ANYTHING HAS TO BE CHANGED!
 */
export interface LinieReifegradWrapper {
  Reifegrad: number;
  name: "ZS RG (%)" | "PRISMA RG (%)";
  Datum: string;
}
export interface LinieReifegradStationAmountWrapper {
  Station: number;
  Datum: string;
}

/**
 * This is the linieMonitor object as persisted by the backend service.
 */
export interface LinieMonitor {
  linieId: string;
  startDate: Date;
  endDate: Date;
  reifegradMapping: Object;
  reifegradZsMapping: Object;
  stationAmountMapping: Object;
  liniePerformance: number;
  linieZsPerformance: number;
}
